<template>
    <el-col :span="12" style="text-align: left; padding-top: 20px">
        <h3 style="font-weight: bold; text-align: left; padding-left: 80px"> {{ title }} </h3>
    </el-col>
    <el-col :span="16" style="text-align: left; padding-top: 10px; padding-left: 80px; font-size: 15px;">
        <!-- <span
            v-for="(line,lineNumber) of description.split('\n')" 
            v-bind:key="lineNumber" >
            {{ line }}<br/>
        </span> -->
        <p v-html="description" style="font-size: 15px;"></p>
    </el-col>
    <el-col v-if="request" :span="8" style="text-align: left; padding-top: 60px">
        <h5 style="font-weight: bold; text-align: left; padding-left: 80px"> Request </h5>
    </el-col>
    <el-col v-if="request" :span="16" style="text-align: left; padding-top: 10px; padding-left: 80px">
        <el-card class="box-card">
            <div style="display: flex; justify-content: space-between; align-items: center;">
                <el-col :span="6" style="color: #00d1fd;"><div class="grid-content ep-bg-purple" /><b>{{ request.toUpperCase() }}</b></el-col>
                <el-col :span="18"><div class="grid-content ep-bg-purple" />{{ endpoint }}</el-col>
            </div>
        </el-card>
    </el-col>
    <el-col v-if="request" :span="8" style="text-align: left; padding-top: 60px">
        <h5 style="font-weight: bold; text-align: left; padding-left: 80px"> Parameters </h5>
    </el-col>
    <el-col v-if="request" :span="16" style="text-align: left; padding-top: 10px; padding-left: 80px">
        <div v-if="parameters">
            <el-table :data="parameters">
                <el-table-column type="expand">
                    <template #default="props">
                        <div m="4">
                            <div v-if="props.row.childparameters">
                                <h6 style="font-weight: bold; text-align: left; padding-top: 10px;"> Child Parameters </h6>
                                <el-table :data="props.row.childparameters" style="text-align: left; padding-top: 10px">

                                    <el-table-column type="expand">
                                        <template #default="props">
                                            <div m="4" v-if="props.row.description">
                                                <p m="t-0 b-2">{{ props.row.description }}</p>
                                            </div>
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="Name">
                                        <template #default="props">
                                            <div style="display: flex; align-items: center">
                                                <span>{{ props.row.name }}</span>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Required">
                                        <template #default="props">
                                            <div style="display: flex; align-items: center">
                                                <span>{{ props.row.required }}</span>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Type">
                                        <template #default="props">
                                            <div style="display: flex; align-items: center">
                                                <span>{{ props.row.type }}</span>
                                            </div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                            <p m="t-0 b-2">{{ props.row.description }}</p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="Name">
                    <template #default="props">
                        <div style="display: flex; align-items: center">
                            <span>{{ props.row.name }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="Required">
                    <template #default="props">
                        <div style="display: flex; align-items: center">
                            <span>{{ props.row.required }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="Type">
                    <template #default="props">
                        <div style="display: flex; align-items: center">
                            <span>{{ props.row.type }}</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div v-else>
            No Parameters.
        </div>
    </el-col>
    <el-col v-if="body" :span="8" style="text-align: left; padding-top: 60px">
        <h5 style="font-weight: bold; text-align: left; padding-left: 80px"> Body </h5>
    </el-col>
    <el-col v-if="body" :span="16" style="text-align: left; padding-top: 10px; padding-left: 80px">
        <el-card class="box-card">
            <pre class="prettyprint">{{ body }}</pre>
        </el-card>
    </el-col>
    <el-col v-if="request" :span="8" style="text-align: left; padding-top: 60px">
        <h5 style="font-weight: bold; text-align: left; padding-left: 80px"> {{ response }} </h5>
    </el-col>
    <el-col v-if="returns" :span="8" style="text-align: left; padding-top: 60px">
        <h5 style="font-weight: bold; text-align: left; padding-left: 80px"> Returns </h5>
    </el-col>
    <el-col v-if="returns" :span="16" style="text-align: left; padding-top: 10px; padding-left: 80px">
        <el-card class="box-card">
            <!-- <samp v-html="formatedReturns" style="font-size: 15px;"></samp> -->
            <pre class="prettyprint">{{ returns }}</pre>
        </el-card>
    </el-col>
    <el-col v-if="sectionNumber == '2'" :span="16" style="text-align: left; padding-top: 40px; padding-left: 80px">
        <el-table :data="errors">
                <el-table-column label="Error Code" width="200">
                    <template #default="props">
                    <div style="display: flex; align-items: center; font-weight: bold">
                        <span>{{ props.row.code }}</span>
                    </div>
                    </template>
                </el-table-column>
                <el-table-column label="Definition">
                    <template #default="props">
                    <div style="display: flex; align-items: center">
                        <span>{{ props.row.definition }}</span>
                    </div>
                    </template>
                </el-table-column>
            </el-table>
    </el-col>
</template>

<script>
import { ref, computed } from 'vue';
export default {
    props: [
        'sectionNumber',
        'title',
        'description',
        'request',
        'endpoint',
        'parameters',
        'childparameters',
        'body',
        'returns'
    ],
    emits: [
        'sectionNumber',
        'title',
        'description',
        'request',
        'endpoint',
        'parameters',
        'childparameters',
        'body',
        'returns'
    ],
    setup(props, { emit }) {

        const sectionNumber = computed({
            get: () => props.sectionNumber,
            set: (value) => emit("update:sectionNumber", value),
        });

        const title = computed({
            get: () => props.title,
            set: (value) => emit("update:title", value),
        });

        const description = computed({
            get: () => props.description,
            set: (value) => emit("update:description", value),
        });

        const request = computed({
            get: () => props.request,
            set: (value) => emit("update:request", value),
        });

        const endpoint = computed({
            get: () => props.endpoint,
            set: (value) => emit("update:endpoint", value),
        });

        const parameters = computed({
            get: () => props.parameters,
            set: (value) => emit("update:parameters", value),
        });

        const childparameters = computed({
            get: () => props.childparameters,
            set: (value) => emit("update:childparameters", value),
        });

        const body = computed({
            get: () => props.body,
            set: (value) => emit("update:body", value),
        });

        const returns = computed({
            get: () => props.returns,
            set: (value) => emit("update:returns", value),
        });

        const response = computed(() => {
            if (request.value == "ping") {
                return "Response: PONG"
            }
            return "Response: 200 OK"
        });

        // const formatedReturns = computed(() => {
        //     // sample json:
        //     // "returns": "[<3>{<6>\"name\": <ss>\"network1\"<s>,<6>\"dimensions\": [<9><sn>8.715<s>,<9><sn>8.715<s>,<9><sn>2<s><6>],<6>\"image\": <ss>\"floorplan\"<s><3>}<0>]"
        //     var result = returns.value.replace(/<3>/g, "<br/>&nbsp&nbsp&nbsp")
        //     result = result.replace(/<6>/g, "<br/>&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp")
        //     result = result.replace(/<9>/g, "<br/>&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp")
        //     result = result.replace(/<0>/g, "<br/>")
        //     result = result.replace(/<ss>/g, "<span style='color:#9B0000'>")
        //     result = result.replace(/<sn>/g, "<span style='color:#3E6D9C'>")
        //     result = result.replace(/<s>/g, "</span>")
        //     return result
        // })
        
        const errors = [
            {"code": 200, "definition": "OK"},
            {"code": 400, "definition": "Bad Request: Your request failed, often due to a missing parameter"},
            {"code": 401, "definition": "Unauthorized: Your API key is not valid"},
            {"code": 403, "definition": "Forbidden: The API key doesn’t have permission to perform the request"},
            {"code": 404, "definition": "Not Found: The request resource doesn’t exist"},
            {"code": 406, "definition": "Not Acceptable: Object already exists"},
            {"code": 444, "definition": "License has expired: You need to contact IOSEA team to update your license"},
            {"code": 500, "definition": "Internal Server Errors: We had an issue with our server"}
        ]

        return { 
            sectionNumber,
            title, 
            description, 
            request, 
            endpoint, 
            parameters,
            childparameters,
            body, 
            returns,
            response,
            errors
        }
    }
}
</script>

<style>

.item {
  margin-bottom: 18px;
}

.box-card {
  width: 480px;
}
</style>