import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'

const firebaseConfig = {
  apiKey: "AIzaSyAYDWzh54J0Jg-f4wnq63m-3gCsnui9Rvc",
  authDomain: "ioseaplethora.firebaseapp.com",
  databaseURL: "https://ioseaplethora.firebaseio.com",
  projectId: "ioseaplethora",
  storageBucket: "ioseaplethora.appspot.com",
  messagingSenderId: "1010925304230",
  appId: "1:1010925304230:web:9a72345a425286676df767",
  measurementId: "G-EQLMFQ6B1W"
};

  // Initialize Firebase
  const firebaseApp = firebase.initializeApp(firebaseConfig)

  firebaseApp.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)

  const projectAuth = firebase.auth()
  const projectFirestore = firebase.firestore()
  const timestamp = firebase.firestore.FieldValue.serverTimestamp

  export { projectAuth, projectFirestore, timestamp }