<template>
  <el-row align="top">
    <el-col :span="5">
      <el-col :span="10">
        <div class="demo-image">
          <el-image style="width: 100px; height: 100px" src="/ioseaapi.png" fit="contain" />
        </div>
      </el-col>
    </el-col>
    <el-col :span="19">
      <div v-if="user">
          <ul class="my-auto text-right" style="list-style-type: none; padding-top: 30px; padding-right: 30px;">
          <li>
            <el-dropdown split-button trigger="click" @command="handleCommand">
              <span class="user-email">{{ user.email }}</span>
              <template #dropdown>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click="onClickLogout">Logout</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </li>
        </ul>
      </div>
    </el-col>
  </el-row>
  <el-row align="top">
    <div style="text-align: left; font-size: 13px; font-weight: bold; padding-left: 20px; height: 40px; padding-top: 10px">API version</div>
    <el-col :span="2" style="text-align: left;">
      <el-select v-model="selectedAPIVersion" class="m-2" style="width: 40%" placeholder="Select" size="small">
        <el-option
          v-for="item in apiVersions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </el-col>
  </el-row>
  <el-row align="top">
    <el-col :span="5">
      <el-scrollbar height="77vh">
        <el-menu
          default-active="1"
          class="el-menu-vertical-demo"
          @select="handleSelect"
        >
          <el-menu-item index="1">Introduction</el-menu-item>
          <el-menu-item index="2">Errors</el-menu-item>
          <el-menu-item index="3">Base URL</el-menu-item>
          <el-sub-menu index="4">
            <template #title>HTTP Requests</template>
            <el-sub-menu index="4-1">
              <template #title>Network</template>
              <el-menu-item v-if="selectedAPIVersion!='1x'" index="4-1-1">Create Network</el-menu-item>
              <el-menu-item v-if="selectedAPIVersion!='1x'" index="4-1-2">Update Network</el-menu-item>
              <el-menu-item v-if="selectedAPIVersion!='1x'" index="4-1-3">Fetch All Networks</el-menu-item>
              <el-menu-item v-if="selectedAPIVersion!='1x'" index="4-1-4">Fetch Network Details</el-menu-item>
              <el-menu-item v-if="selectedAPIVersion!='1x'" index="4-1-5">Reset Network Settings</el-menu-item>
              <el-menu-item v-if="selectedAPIVersion!='1x'" index="4-1-6">Delete Network</el-menu-item>
              <el-menu-item v-if="selectedAPIVersion!='1x'" index="4-1-7">Clear Cache</el-menu-item>

              <el-menu-item v-if="selectedAPIVersion=='1x'" index="4-1-1">Create Network</el-menu-item>
              <el-menu-item v-if="selectedAPIVersion=='1x'" index="4-1-2">Create Network (default settings)</el-menu-item>
              <el-menu-item v-if="selectedAPIVersion=='1x'" index="4-1-3">Fetch All Networks</el-menu-item>
              <el-menu-item v-if="selectedAPIVersion=='1x'" index="4-1-4">Fetch Network Details</el-menu-item>
              <el-menu-item v-if="selectedAPIVersion=='1x'" index="4-1-5">Update aoa Settings</el-menu-item>
              <el-menu-item v-if="selectedAPIVersion=='1x'" index="4-1-6">Update ac Settings</el-menu-item>
              <el-menu-item v-if="selectedAPIVersion=='1x'" index="4-1-7">Update clusteringsig Settings</el-menu-item>
              <el-menu-item v-if="selectedAPIVersion=='1x'" index="4-1-8">Update clustering Settings</el-menu-item>
              <el-menu-item v-if="selectedAPIVersion=='1x'" index="4-1-9">Update filtering Settings</el-menu-item>
              <el-menu-item v-if="selectedAPIVersion=='1x'" index="4-1-10">Update filtering2 Settings</el-menu-item>
              <el-menu-item v-if="selectedAPIVersion=='1x'" index="4-1-11">Update rtls Settings</el-menu-item>
              <el-menu-item v-if="selectedAPIVersion=='1x'" index="4-1-12">Update kalman Settings</el-menu-item>
              <el-menu-item v-if="selectedAPIVersion=='1x'" index="4-1-13">Delete Network</el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="4-2">
              <template #title>Locator</template>
              <el-menu-item index="4-2-1">Add Locator</el-menu-item>
              <el-menu-item index="4-2-2">Add Locator (MAC address only)</el-menu-item>
              <el-menu-item index="4-2-3">Fetch Locator</el-menu-item>
              <el-menu-item index="4-2-4">Update Locator Location</el-menu-item>
              <el-menu-item index="4-2-5">Update Locator Field of View</el-menu-item>
              <el-menu-item index="4-2-6">Update Locator IMU</el-menu-item>
              <el-menu-item index="4-2-7">Update Locator Sensitivity</el-menu-item>
              <el-menu-item index="4-2-8">Acquire Automatic Locator Orientation</el-menu-item>
              <el-menu-item index="4-2-9">Connect Locator</el-menu-item>
              <el-menu-item index="4-2-10">Disconnect Locator</el-menu-item>
              <el-menu-item index="4-2-11">Start Locator</el-menu-item>
              <el-menu-item index="4-2-12">Stop Locator</el-menu-item>
              <el-menu-item index="4-2-13">Reboot Locator</el-menu-item>
              <el-menu-item index="4-2-14">Move Locator to Different Network</el-menu-item>
              <el-menu-item index="4-2-15">Locator Status</el-menu-item>
              <el-menu-item index="4-2-16">Locator IMU</el-menu-item>
              <el-menu-item index="4-2-17">Delete Locator</el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="4-3">
              <template #title>Tag</template>
              <el-menu-item index="4-3-1">Register Tag</el-menu-item>
              <el-menu-item index="4-3-2">Delete Tag</el-menu-item>
            </el-sub-menu>
            <el-sub-menu v-if="selectedAPIVersion=='3x'" index="4-4">
              <template #title>Account</template>
              <el-menu-item index="4-4-1">Expiry Date</el-menu-item>
            </el-sub-menu>
          </el-sub-menu>
          <el-sub-menu index="5">
            <template #title>Websocket</template>
            <el-sub-menu index="5-1">
              <template #title>Websocket Client</template>
              <el-menu-item index="5-1-1">Add User</el-menu-item>
              <el-menu-item index="5-1-2">Delete User</el-menu-item>
            </el-sub-menu>
            <el-menu-item index="5-2">Tracking</el-menu-item>
            <el-menu-item index="5-3">Tracking All</el-menu-item>
            <el-menu-item index="5-4">Tracking Few</el-menu-item>
            <el-menu-item index="5-5">Thermometer</el-menu-item>
            <el-menu-item index="5-6">Accelerometer</el-menu-item>
            <el-menu-item index="5-7">Monitoring</el-menu-item>
            <el-sub-menu index="5-8">
              <template #title>Gateway Client (Locator) Connection</template>
              <el-menu-item index="5-8-1">Status Update using JSON</el-menu-item>
              <el-menu-item index="5-8-2">Status Update using text</el-menu-item>
            </el-sub-menu>
          </el-sub-menu>
          <el-sub-menu index="6">
            <template #title>Health Check</template>
            <el-sub-menu v-if="selectedAPIVersion=='1x' || selectedAPIVersion=='2x'" index="6-1">
              <template #title>Concurrent Connections</template>
              <el-menu-item index="6-1-1">Gateway</el-menu-item>
              <el-menu-item index="6-1-2">Tracking</el-menu-item>
              <el-menu-item index="6-1-3">Thermometer</el-menu-item>
              <el-menu-item index="6-1-4">Accelerometer</el-menu-item>
              <el-menu-item index="6-1-5">Monitoring</el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="6-2">
              <template #title>Microservices</template>
              <el-menu-item index="6-2-1">API</el-menu-item>
              <el-menu-item v-if="selectedAPIVersion=='1x' || selectedAPIVersion=='2x'" index="6-2-2">Services Status</el-menu-item>
              <el-menu-item index="6-2-3">Locator Status</el-menu-item>
            </el-sub-menu>
          </el-sub-menu>
          <el-sub-menu index="7">
            <template #title>Deployment</template>
            <el-sub-menu index="7-1">
              <template #title>Protobuf</template>
              <el-menu-item index="7-1-1">Default</el-menu-item>
              <el-menu-item index="7-1-2">Telemetry</el-menu-item>
            </el-sub-menu>
          </el-sub-menu>
          <el-sub-menu v-if="displayDockerCompose && selectedAPIVersion!='3x'" index="7-2">
            <template #title>VM</template>
            <el-menu-item index="7-2-1">docker-compose</el-menu-item>
          </el-sub-menu>
        </el-menu>
      </el-scrollbar>
    </el-col>

    <el-col :span="19">
      <el-scrollbar height="77vh">
        <div v-if="isHTTPRequest" style="container-fluid; min-height: 90vh; padding-bottom: 50px;">
          <HTTPRequest
          v-model:sectionNumber="sectionNumber"
          v-model:title="title"
          v-model:description="description"
          v-model:request="request"
          v-model:endpoint="endpoint"
          v-model:parameters="parameters"
          v-model:childparameters="childparameters"
          v-model:body="body"
          v-model:returns="returns"
        />
        </div>
        <div v-if="isWebsocket" style="container-fluid; min-height: 90vh; padding-bottom: 50px;">
          <Websocket
          v-model:sectionNumber="sectionNumber"
          v-model:title="title"
          v-model:shortDescription="shortDescription"
          v-model:description="description"
          v-model:endpoint="endpoint"
          v-model:returns="returns"
          v-model:onOpenDesc="onOpenDesc"
          v-model:onCloseDesc="onCloseDesc"
        />
        </div>
        <div v-if="isFile" style="container-fluid; min-height: 90vh; padding-bottom: 50px;">
          <File
          v-model:sectionNumber="sectionNumber"
          v-model:title="title"
          v-model:content="content"
          v-model:fileName="fileName"
        />
        </div>
      </el-scrollbar>
    </el-col>
  </el-row>
    <footer class="footer mt-auto py-2">
      <div style="font-size: 14px;">Copyright © 2024 IOSEA Inc. All rights reserved.</div>
      <div>
        <p style="font-size: 13px;">Version 09-02-2024</p>
      </div>
    </footer>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { useRouter } from "vue-router";
import HTTPRequest from "../components/HTTPRequest.vue";
import Websocket from "../components/Websocket.vue";
import File from "../components/File.vue";
import jsonv1 from '../json/details-v1.json';
import jsonv2_0_x from '../json/details-v2.json';
import jsonv3_0_x from '../json/details-v3.json';
import getUser from "../composables/getUser";
import useLogout from "../composables/useLogout";
import { ArrowDown } from '@element-plus/icons-vue'

export default {
  name: 'Home',
  components: { HTTPRequest, Websocket, File },
  setup() {
    const router = useRouter();
    const { user } = getUser();
    const { logout, error } = useLogout();
    const sectionNumber = ref("1")
    const title = ref("")
    const shortDescription = ref("")
    const description = ref("")
    const request = ref(null)
    const endpoint = ref(null)
    const parameters = ref(null)
    const childparameters = ref(null)
    const body = ref(null)
    const returns = ref(null)
    const onOpenDesc = ref(null)
    const onCloseDesc = ref(null)
    const content = ref(null)
    const fileName = ref(null)
    const isHTTPRequest = ref(true)
    const isWebsocket = ref(false)
    const isFile = ref(false)
    const email = ref("")
    
    const apiVersions = [
      {
        value: '1x',
        label: '1x',
      },
      {
        value: '2x',
        label: '2x',
      },
      {
        value: '3x',
        label: '3x',
      }
    ]
    const selectedAPIVersion = ref('3x')

    const displayDockerCompose = ref(window.localStorage.getItem("displayDockerCompose") == 'true' ? true : false)
    
    const handleSelect = (key, keyPath) => {
      sectionNumber.value = key
      if (selectedAPIVersion.value == '1x') {
        title.value = jsonv1[key].title
        shortDescription.value = jsonv1[key].shortDescription
        description.value = jsonv1[key].description
        request.value = jsonv1[key].request
        endpoint.value = jsonv1[key].endpoint
        parameters.value = jsonv1[key].parameters
        childparameters.value = jsonv1[key].childparameters
        body.value = jsonv1[key].body
        returns.value = jsonv1[key].returns
        onOpenDesc.value = jsonv1[key].onOpenDesc
        onCloseDesc.value = jsonv1[key].onCloseDesc
        content.value = jsonv1[key].content
        fileName.value = jsonv1[key].fileName
      }
      else if (selectedAPIVersion.value == '2x') {
        title.value = jsonv2_0_x[key].title
        shortDescription.value = jsonv2_0_x[key].shortDescription
        description.value = jsonv2_0_x[key].description
        request.value = jsonv2_0_x[key].request
        endpoint.value = jsonv2_0_x[key].endpoint
        parameters.value = jsonv2_0_x[key].parameters
        childparameters.value = jsonv2_0_x[key].childparameters
        body.value = jsonv2_0_x[key].body
        returns.value = jsonv2_0_x[key].returns
        onOpenDesc.value = jsonv2_0_x[key].onOpenDesc
        onCloseDesc.value = jsonv2_0_x[key].onCloseDesc
        content.value = jsonv2_0_x[key].content
        fileName.value = jsonv2_0_x[key].fileName
      }
      else if (selectedAPIVersion.value == '3x') {
        title.value = jsonv3_0_x[key].title
        shortDescription.value = jsonv3_0_x[key].shortDescription
        description.value = jsonv3_0_x[key].description
        request.value = jsonv3_0_x[key].request
        endpoint.value = jsonv3_0_x[key].endpoint
        parameters.value = jsonv3_0_x[key].parameters
        childparameters.value = jsonv3_0_x[key].childparameters
        body.value = jsonv3_0_x[key].body
        returns.value = jsonv3_0_x[key].returns
        onOpenDesc.value = jsonv3_0_x[key].onOpenDesc
        onCloseDesc.value = jsonv3_0_x[key].onCloseDesc
        content.value = jsonv3_0_x[key].content
        fileName.value = jsonv3_0_x[key].fileName
      }

      var keyFirstChar = key.charAt(0)
      var twoKeys = key.slice(0,3)

      if (twoKeys == "5-1" || keyFirstChar == "1" || keyFirstChar == "2" || keyFirstChar == "3" || keyFirstChar == "4" || keyFirstChar == "6") {
        isHTTPRequest.value = true
        isWebsocket.value = false
        isFile.value = false
      }
      if (keyFirstChar == "5" && twoKeys != "5-1") {
        isHTTPRequest.value = false
        isWebsocket.value = true
        isFile.value = false
      }
      if (keyFirstChar == "7") {
        isHTTPRequest.value = false
        isWebsocket.value = false
        isFile.value = true
      }

      // Scroll to the top the page
      window.scrollTo(0,0);
    }

    const onClickLogout = async () => {
      await logout();
      if (error.value) {
        console.log("Error - Logout Failed")
      }
    };

    watch(user, () => {
      if (!user.value) {
        router.push({ name: "SignIn" });
      }
    });

    watch(selectedAPIVersion, () => {
      handleSelect(sectionNumber.value,[sectionNumber.value])
    });

    onMounted(() => {
      // Populate the Introduction for the first time - api version 2
      sectionNumber.value = "1"
      title.value = jsonv3_0_x["1"].title
      description.value = jsonv3_0_x["1"].description
      request.value = jsonv3_0_x["1"].request
      endpoint.value = jsonv3_0_x["1"].endpoint
      parameters.value = jsonv3_0_x["1"].parameters
      childparameters.value = jsonv3_0_x["1"].childparameters
      body.value = jsonv3_0_x["1"].body
      returns.value = jsonv3_0_x["1"].returns
    })

    return {
      onClickLogout,
      user,
      email,
      sectionNumber, 
      handleSelect, 
      title,
      shortDescription, 
      description,
      request,
      endpoint,
      parameters,
      childparameters,
      body,
      returns,
      onOpenDesc,
      onCloseDesc,
      isHTTPRequest,
      isWebsocket,
      isFile,
      content,
      fileName,
      ArrowDown,
      displayDockerCompose,
      apiVersions,
      selectedAPIVersion 
    }
  }
}
</script>

<style scoped>
.demo-image .block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  display: inline-block;
  width: 20%;
  box-sizing: border-box;
  vertical-align: top;
}
.demo-image .block:last-child {
  border-right: none;
}
.demo-image .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 20px;
}
a.dropdown-toggle {
   border: none;
   outline: none;
   text-decoration: none;
   color: black;
}
.dropdown-menu {
    height: 45px !important;
    font-size: 14px;
    color: #00d1fd;
}
</style>
