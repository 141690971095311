<template>
    <form @submit.prevent="handleSubmit">
        <input type="email" required placeholder="email" v-model="email">
        <input type="password" required placeholder="password" v-model="password">
        <div class="error"> {{ error }}</div>
        <button class="btn btn-primary" style="width: 100%; border-radius: 20px;">
            <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Sign In
        </button>
    </form>
</template>

<script>
import { ref } from 'vue'
import useLogin from '../composables/useLogin'
export default {
    setup(props, context) {
        // refs
        const email = ref("")
        const password = ref("")
        const loading = ref(false)

        const { error, login } = useLogin()

        const handleSubmit = async () => {
            loading.value = true
            await login(email.value, password.value)
            loading.value = false
            if (!error.value) {
                context.emit('login')
            }
        }

        return { 
            email, 
            password, 
            handleSubmit, 
            error, 
            loading
        }
    }
}
</script>