import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// import firebase auth serive
import { projectAuth } from './firebase/config'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import jQuery from 'jquery'
import 'bootstrap/dist/css/bootstrap.css'
import './assets/main.css'

global.jQuery = jQuery
global .$ = jQuery

let app

projectAuth.onAuthStateChanged(() => {
    if (!app) {
        app = createApp(App)
        .use(router)
        .use(ElementPlus)
        .mount('#app')
    }
})