import { createRouter, createWebHistory } from 'vue-router'
import SignIn from '../views/SignIn.vue'
import Home from '../views/Home.vue'
import NotFound from '../views/NotFound.vue'
import { projectAuth } from '../firebase/config'

// auth guard
const requireAuth = (to, from, next) => {
  let user = projectAuth.currentUser
  if (!user) {
    next({name: 'SignIn'})
  } else {
    next()
  }
}

const requireNoAuth = (to, from, next) => {
  let user = projectAuth.currentUser
  if (user) {
    next({name: 'Home'})
  } else {
    next()
  }
}

const routes = [
  {
    path: '/',
    name: 'SignIn',
    component: SignIn,
    beforeEnter: requireNoAuth
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: requireAuth
  },
  // catchall 404
  {
    path: '/:catchAll(.*)',
    name: NotFound,
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
