<template>
  <div class="row align-items-center" style="container-fluid; min-height: 90vh;">
    <div class="signin customContainer" style="margin: 50px auto;">
      <div class="demo-image">
        <el-image style="width: 140px; height: 140px" src="ioseaapi.png" fit="scale-down" />
      </div>
      <LoginForm @login="enterHomePage"/> 
    </div>
  </div>
  <footer class="footer mt-auto py-4py">
    <div style="font-size: 14px;">Copyright © 2024 IOSEA Inc. All rights reserved.</div>
    <div>
      <p style="font-size: 13px;">Version 09-02-2024</p>
    </div>
  </footer>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { projectAuth } from '../firebase/config';
import { projectFirestore } from '../firebase/config';
import LoginForm from "../components/LoginForm.vue";
import { ElNotification } from 'element-plus';
import useLogout from "../composables/useLogout";

export default {
  components: { LoginForm },
  setup() {
    const router = useRouter();
    const { logout, error } = useLogout();

    const enterHomePage = () => {
      let user = projectAuth.currentUser
      var displayDockerCompose = false
      projectFirestore.collection('users').doc(user.uid).get()
        .then((doc) => {
            if (doc.exists) {
                displayDockerCompose = doc.data().display_docker_compose || false
            } 
            // else {
            //   onError("Couldn't find user details")
            //   onLogout()
            // }
            window.localStorage.setItem('displayDockerCompose', displayDockerCompose)
            router.push({ name: "Home" });

        }).catch((error) => {
          // onError("Couldn't get user details")
          // onLogout()
          console.log("Error getting user details:", error);
          window.localStorage.setItem('displayDockerCompose', displayDockerCompose)
          router.push({ name: "Home" });
        });
    };

    const onLogout = async () => {
      await logout();
      if (!error.value) {
        console.log("user logged out");
      }
    };

    const onSuccess = (description) => {
      ElNotification({
        title: 'Success',
        message: description,
        type: 'success',
        duration: 2500
      })
    }

    const onError = (description) => {
      ElNotification({
        title: 'Error',
        message: description,
        type: 'error',
        duration: 5000
      })
    }

    return { enterHomePage };
  }
};
</script>

<style>
.signin {
  text-align: center;
}
.signin form {
  width: 300px;
  margin: 10px auto;
}
.signin label {
  display: block;
  margin: 20px 0 10px;
}
.signin input {
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #eee;
  outline: none;
  color: black;
  margin: 10px auto;
}
.signin button {
  margin: 20px auto;
}
.demo-image .block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  display: inline-block;
  width: 50%;
  box-sizing: border-box;
  vertical-align: top;
}
.demo-image .block:last-child {
  border-right: none;
}
.demo-image .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 20px;
}
</style>
