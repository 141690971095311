<template>
    <el-col :span="12" style="text-align: left; padding-top: 20px">
        <h3 style="font-weight: bold; text-align: left; padding-left: 80px"> {{ title }} </h3>
    </el-col>
    <el-col :span="16" style="text-align: left; padding-top: 10px; padding-left: 80px; font-size: 15px;">
        <p style="font-size: 15px;">{{ shortDescription }}</p>
    </el-col>
    <el-col :span="16" style="text-align: left; padding-top: 10px; padding-left: 80px; font-size: 15px;">
        <p style="font-size: 15px;">Create a new websocket connection using the below url.</p>
    </el-col>
    <el-col v-if="endpoint" :span="8" style="text-align: left; padding-left: 80px; padding-top: 20px">
        <h5 style="font-weight: bold; text-align: left"> Websocket URL </h5>
    </el-col>
    <el-col v-if="endpoint" :span="16" style="text-align: left; padding-top: 10px; padding-left: 80px">
        <el-card class="box-card">
            <div style="display: flex; justify-content: space-between; align-items: center;">
                <el-col><div class="grid-content ep-bg-purple" />{{ endpoint }}</el-col>
            </div>
        </el-card>
    </el-col>
    <el-col :span="16" style="text-align: left; padding-top: 40px; padding-left: 80px; font-size: 15px;">
        <p v-html="description" style="font-size: 15px;"></p>
    </el-col>
    <el-col v-if="onOpenDesc" :span="8" style="text-align: left; padding-left: 80px; padding-top: 20px">
        <h5 style="font-weight: bold; text-align: left">onOpen</h5>
        <p style="font-size: 15px;">Connection established.</p>
    </el-col>
    <el-col v-if="onOpenDesc" :span="16" style="text-align: left; padding-top: 10px; padding-left: 80px">
        <el-card class="box-card">
            <p v-html="onOpenDesc" style="font-size: 15px;"></p>
        </el-card>
    </el-col>
    <el-col v-if="returns" :span="8" style="text-align: left; padding-left: 80px; padding-top: 60px">
        <h5 style="font-weight: bold; text-align: left">onMessage</h5>
        <p style="font-size: 15px;">Data received. (JSON)</p>
    </el-col>
    <el-col v-if="returns" :span="16" style="text-align: left; padding-top: 10px; padding-left: 80px">
        <el-card class="box-card">
            <pre class="prettyprint">{{ returns }}</pre>
        </el-card>
    </el-col>
    <el-col v-if="onCloseDesc" :span="8" style="text-align: left; padding-left: 80px; padding-top: 60px">
        <h5 style="font-weight: bold; text-align: left"> Close Websocket Connection </h5>
    </el-col>
    <el-col v-if="returns" :span="16" style="text-align: left; padding-left: 80px; padding-top: 10px">
        <p v-html="onCloseDesc" style="font-size: 15px;"></p>
    </el-col>
</template>

<script>
import { ref, computed } from 'vue';
export default {
    props: [
        'sectionNumber',
        'title',
        'shortDescription',
        'description',
        'endpoint',
        'returns',
        'onOpenDesc',
        'onCloseDesc'
    ],
    emits: [
        'sectionNumber',
        'title',
        'shortDescription',
        'description',
        'endpoint',
        'returns',
        'onOpenDesc',
        'onCloseDesc'
    ],
    setup(props, { emit }) {

        const sectionNumber = computed({
            get: () => props.sectionNumber,
            set: (value) => emit("update:sectionNumber", value),
        });

        const title = computed({
            get: () => props.title,
            set: (value) => emit("update:title", value),
        });

        const shortDescription = computed({
            get: () => props.shortDescription,
            set: (value) => emit("update:shortDescription", value),
        });

        const description = computed({
            get: () => props.description,
            set: (value) => emit("update:description", value),
        });

        const endpoint = computed({
            get: () => props.endpoint,
            set: (value) => emit("update:endpoint", value),
        });

        const returns = computed({
            get: () => props.returns,
            set: (value) => emit("update:returns", value),
        });

        const onOpenDesc = computed({
            get: () => props.onOpenDesc,
            set: (value) => emit("update:onOpenDesc", value),
        });

        const onCloseDesc = computed({
            get: () => props.onCloseDesc,
            set: (value) => emit("update:onCloseDesc", value),
        });

        return { 
            sectionNumber,
            title,
            shortDescription, 
            description,
            endpoint,
            returns,
            onOpenDesc,
            onCloseDesc
        }
    }
}
</script>

<style>

.item {
  margin-bottom: 18px;
}

.box-card {
  width: 480px;
}
</style>