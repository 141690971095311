<template>
    <el-col :span="12" style="text-align: left; padding-top: 20px">
        <h3 style="font-weight: bold; text-align: left; padding-left: 80px"> {{ title }} </h3>
    </el-col>
    <el-col v-if="content" :span="16" style="text-align: left; padding-top: 10px; padding-left: 80px">
        <el-card class="box-card">
            <p v-html="content" style="font-size: 15px;"></p>
        </el-card>
    </el-col>
    <el-col v-if="fileName" :span="8" style="text-align: left; padding-left: 80px; padding-top: 50px">
        <a style="color: #00d1fd;" href="/files/docker-compose.yml" download>Download File</a>
    </el-col>
</template>

<script>
import { ref, computed } from 'vue';
export default {
    props: [
        'sectionNumber',
        'title',
        'content',
        'fileName'
    ],
    emits: [
        'sectionNumber',
        'title',
        'content',
        'fileName'
    ],
    setup(props, { emit }) {

        const sectionNumber = computed({
            get: () => props.sectionNumber,
            set: (value) => emit("update:sectionNumber", value),
        });

        const title = computed({
            get: () => props.title,
            set: (value) => emit("update:title", value),
        });

        const content = computed({
            get: () => props.content,
            set: (value) => emit("update:content", value),
        });

        const fileName = computed({
            get: () => props.fileName,
            set: (value) => emit("update:fileName", value),
        });

        return { 
            sectionNumber,
            title,
            content,
            fileName
        }
    }
}
</script>

<style>

.item {
  margin-bottom: 18px;
}

.box-card {
  width: 480px;
}
</style>